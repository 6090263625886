@import "/src/theme/default";

.home-tile {
    display: flex;
    flex-direction: column;
    height: 100%;
  
    .content {
      flex-grow: 1;
      margin-bottom: 20px;
    }
  
    .button-group {
      display: flex;
      justify-content: center;
      margin-top: auto;
  
      a {
        text-decoration: none;
      }
  
      .button-primary {
        text-align: center;
      }
    }
  }
  
  