@import "/src/theme/default";

.breadcrumb-container {
  font-size: 0.8rem;
  position: sticky;
  padding-top: 1px;
  top: 71px;
  background-color: white;
  z-index: 11;
  padding-top: 0.5rem;
  .breadcrumb-link {
    .breadcrumb-arrow {
      opacity: 0.6;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .last-breadcrumb {
    font-weight: 600;
    cursor: default;
    opacity: 0.8;
  }

  hr {
    margin-top: 0.5rem;
    opacity: 0.1;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
