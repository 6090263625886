.no-arrow-dropdown .dropdown-toggle::after {
    display: none; /* Hide the dropdown arrow */
}

.no-arrow-dropdown .dropdown-divider {
    border-top: 1px solid #e7edf3;
}

.person-info-dropdown-item {
    font-size: large;
    font-weight: bold;
    pointer-events: none;
    cursor: default;
}
  