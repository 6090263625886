@import '../../variables.scss';

.tokens-header {
  background: white;
  position: sticky;
  top: $header-top-offset;
  z-index: 10;
  padding: 0 20px;

  .tokens-header-inner {
    padding: 20px 0 0;
    border-bottom: 1px solid #e0e0e0;

    .d-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1.tokens-title {
      margin: 0;
      color: #333;
      font-size: 1.5rem;
    }

    .generate {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      padding: 10px 20px;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      .bi-fingerprint {
        font-size: 24px;
        margin-left: 8px;
      }
    }

    .tokens-description {
      color: #555;
      font-size: 1rem;
      padding-top: 10px;
    }
  }
}

.metadata-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

