@import "/src/theme/default";

.home-header {

    background-color: $secondary-bg-subtle;
    //background-color: lighten(map-get($theme-colors, "secondary"), 60) ;
    padding-bottom: 1.5rem;

    .content{
        max-width: 70rem;
    }

    h1.title{
        font-size: 3.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    p.lead{
        font-size: 1.25rem;
    }

    p.info{
        margin-top: 1.5rem;
    }
}
