@import '../../variables.scss';
@import '../../common-header.scss';

.entries-header {
  @extend .common-header;
}

.knowledge-base-container {
  .text-and-title h3 {
    font-size: 1rem;
  }
}
