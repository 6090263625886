@import './theme/default';

// @import "~bootstrap/scss/root";

// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/helpers";
// @import "~bootstrap/scss/utilities/api";

@import "~bootstrap/scss/bootstrap";

.btn{
    font-weight: 500;
    text-transform: uppercase;
}

.link{
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
}

.btn:hover{
    box-shadow: 0px 0px 5px 2px rgba($color: #000000, $alpha: 0.15);
    transition: 300ms;
}

.flex-1{
    flex: 1;
}

.full-page{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

strong{
    font-weight: 500;
}

.search{
    min-width: 20rem;
}