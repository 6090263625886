.empty-list-message {
    text-align: center;
    font-size: 1.2rem;
    padding: 20px;
  
    .button-group {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .circle-button {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      align-items: center;
      justify-content: center;
      margin-top: 100px;
      border: 1px solid #6c757d;
      font-size: 1rem; 
    }
  
    .icon-title {
      margin-right: 10px;
    }
  
    .empty-list-title {
      margin-bottom: 20px;
      color: #343a40;
    }
  
    .empty-list-text {
      margin-bottom: 30px;
    }
  }
  
  