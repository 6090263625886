.token-modal {
    .modal-dialog{
        max-width: 750px;
        width: 100%;
    }
  }
  
  .copy-icon {
    cursor: pointer;
  
    &:hover {
      color: #0056b3;
    }
  }
  
  .token-display {
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-family: monospace;
    font-size: 1.1rem;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    
    .token-text{
      word-break: break-all;
    }
  }
  