@import "~bootstrap/scss/functions";

$primary: #3D689F;
$secondary: #6c7279;
// $success:
// $info:
$warning: #fad714;
$danger: #E00F00;
// $light:
$dark: #161616;

$enable-rounded: true;

$hr-opacity: 0.15;


@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";


