.list-view {
  display: flex;
  flex-direction: column;
}

.button-row {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-button {
  display: flex;
  align-items: center;
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.btn-icon {
  padding: 8px;
  font-size: 18px;
  color: #495057;
  background-color: transparent;
  
  svg {
    font-size: 16px;
  }
}

.list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 15px 10px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:focus-within {
    background-color: #f8f8f8;
  }
}

.icon-box {
  width: 60px;
  height: 60px;
  opacity: 60%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  background-color: transparent;
}

.text-and-title {
  flex: 1;
  padding-left: 20px;
  position: relative;

  .title-text {
    font-size: 0.9rem;
    color: #6c757d;
    font-weight: bold;
  }

  .list-item-text {
    padding-right: 10px;
    overflow-wrap: break-word;
  }
}

.metadata-and-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  white-space: nowrap;
}

.metadata {
  font-size: 0.85rem;
  color: #495057;
}

.actions {
  display: flex;
  gap: 10px;
  padding-top: 10px;
}

button {
  color: #6c757d;
  background-color: transparent;
  border: none;

  &:hover {
    color: #343a40;
  }
}

.tags {
  margin-top: 5px;
  font-size: 0.85rem;
}

.empty-list {
  text-align: center;
  padding: 50px 0;
  color: #888;

  h4 {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    color: #666;
  }

  svg {
    color: #888;
  }
}

.export-import-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .btn-icon {
    font-size: 18px;
    color: #495057;
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: transparent;
    }

    svg {
      font-size: 16px;
    }
  }
}