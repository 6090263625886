.local-spinner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
  
  .local-spinner-message {
    margin-top: 10px;
    font-size: 1rem;
    color: #495057;
  }
  