@import './variables.scss';

.common-header {
  background: white;
  position: sticky;
  top: $header-top-offset;
  z-index: 10;

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;

    h1 {
      margin: 0;
      color: #333;
      font-size: 1.5rem;
      flex-grow: 1;
    }

    .filter-and-create {
      display: flex;
      gap: 20px;
    }

    .create-button {
      min-width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      padding: 10px 20px;
    }

    .create-button .bi-plus {
      font-size: 24px;
    }

    .category-filter {
      width: 280px;
      background-color: #fff;
      border: 1px solid #ccc;
      color: #333;
      padding: 5px 8px;
      font-size: 0.875rem;
      border-radius: 4px;
      appearance: none;

      &:hover,
      &:focus {
        background-color: #fff;
        border-color: #bbb;
        box-shadow: none;
      }
    }
  }
}

.metadata-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
