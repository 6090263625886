@import '../../variables.scss';
@import '../../common-header.scss';

 .repos-container {  
  .list-view {
    h3 {
      font-size: 1.5rem;
    }
    .list-item {
      cursor: pointer;
    }
  }
 }

 .repository-header {
  @extend .common-header;

  .repository-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .metadata-group-left {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .jira-projects,
      .repository-url {
        font-size: 0.975rem;
        margin: 0;

        a {
          color: #007bff;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .repository-type {
        font-size: 0.975rem;
      }

      .repository-type-value {
        font-weight: bold;
      
        &.git {
          color: #007bff;
        }
      
        &.svn {
          color: #28a745;
        }
      
        &.mercurial {
          color: #ffc107; 
        }
      
        &.default {
          color: #6c757d;
        }
      }
      
    }

    .metadata-group-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      font-size: 0.875rem;
      color: #888;
      text-align: right;
    }
  }
}

.repo-detail-container {
  .list-view {
    .text-and-title h3 {
      font-size: 1rem;
    }
  }
}